@import "abstracts/_abstracts";
.ToggleBase {
    margin: 0;
    width: 3em;
    height: 1.6em;
    transition: var(--animationBase);
    outline: none;
    border-radius: 0;
    position: relative;
    display: inline-block;

    input {
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        z-index: var(--layerPageZIndex);
        cursor: pointer;
    }

    & input:not(:disabled):hover,
    & input:not(:disabled):focus,
    & input:checked {
        border-color: var(--colorBrand);
        background: var(--colorBrand);
    }

    & input:checked + &-body {
        background-color: var(--colorButtonBg);

        &:after {
            background: lightness(var(--colorBrand), 0.64);
            transform: translateX(1.4em);
        }
    }

    &-body {
        width: 100%;
        height: 100%;
        background: lighten(#404040, 65);
        transition: var(--animationBase);

        &:after {
            content: '';
            display: block;
            width: 1.2em;
            height: 1.2em;
            background-color: lighten(#404040, 50);
            transition: var(--animationBase);
            position: absolute;
            top: .2em;
            left: .2em;
        }
    }

    &:disabled,
    &:disabled + * {
        border-color: var(--colorTextSubtle);
        opacity: .4;
    }

    &.is-invalid {
        border-color: var(--colorErrorsDefault);
    }
}
